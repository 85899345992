/* eslint-disable consistent-return */
/* eslint-disable prettier/prettier */
/* eslint-disable react/jsx-no-undef */
import { Button, Card, CardContent, DialogProps, Grid, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import useSound from 'use-sound';
import AudioPlayer from '../audio/AudioPlayer';
import ConfirmDialog from '../confirm-dialog/ConfirmDialog';
import Iconify from '../iconify/Iconify';

interface Props extends DialogProps {

    itemData: any,
}

const GroupFlashCard = ({ itemData, onRemoveCard }: Props) => {
    const [openConfirm, setOpenConfirm] = useState(false);
    const [isPlaying, setIsPlaying] = useState(false);
    const [playSound, { stop, sound }] = useSound(itemData?.audio);
    useEffect(() => {
        if (sound) {
            sound.on('end', () => {
                setIsPlaying(false);
            });

            // Cleanup the event listener
            return () => {
                sound.off('end');
            };
        }
    }, [sound]);

    const handlePlay = () => {
        setIsPlaying(true);
        playSound();
    };

    const stopSound = () => {
        setIsPlaying(false);
        stop();
    };
    const handleCloseConfirm = () => {
        setOpenConfirm(false);
    };

    return <Card>
        <CardContent>
            <Grid container spacing={2} sx={{ mt: 1 }}>
                <Grid item xs={12} display="flex" justifyContent="end">
                    <Button variant="contained" color="error" onClick={() => {
                        setOpenConfirm(true)
                    }}>


                        <Iconify icon="eva:trash-2-outline" />
                        Delete
                    </Button>
                </Grid>
                <Grid item xs={6}>
                    <Typography variant="subtitle2" component="div" className="label">
                        Term : {itemData?.term}
                    </Typography>
                </Grid>
                <Grid item xs={6}>
                    <Typography variant="subtitle2" component="div" className="label">
                        Definition : {itemData?.definition}
                    </Typography>
                </Grid>
                <Grid item xs={6}>
                    <Typography variant="subtitle2" component="div" className="label">
                        Phrase : {itemData?.phrase}
                    </Typography>
                </Grid>
                <Grid item xs={6}>
                    <Typography variant="subtitle2" component="div" className="label">
                        Similar word : {itemData?.SimilarWords?.map((el: any) => el?.word)?.join(', ')}
                    </Typography>
                </Grid>
                <Grid item xs={6}>
                    <Typography
                        display="flex"
                        alignItems="center"
                        gap={2}
                        variant="subtitle2"
                        component="div"
                        className="label"
                    >
                        Image :{' '}
                        {itemData?.image ? (
                            <img src={itemData?.image} alt="" width={60} height={60} />
                        ) : (
                            'Not Found'
                        )}
                    </Typography>
                </Grid>

                <Grid item xs={6}>
                    {/* <Typography display="flex" alignItems="center" gap={2} variant="subtitle2" component="div" className="label">
                        Audio : {itemData?.audio ? <img src={itemData?.audio} alt="" width={60} height={60} /> : "Not Found"}
                      </Typography> */}
                    <Typography display="flex" alignItems="center" gap={2} variant="subtitle2" component="div" className="label">
                        Audio : {itemData?.audio && itemData?.audio?.includes(".mp") ? (
                            // <Button variant="soft" onClick={() => (isPlaying ? stopSound() : handlePlay())}>
                            //     {isPlaying ? 'Pause' : 'Play'}
                            // </Button>
                            <AudioPlayer audioSrc={itemData?.audio} />
                        ) : (
                            'Not found'
                        )}
                    </Typography>
                </Grid>
            </Grid>
        </CardContent>
        <ConfirmDialog
            open={openConfirm}
            onClose={handleCloseConfirm}
            title="Delete"
            content="Are you sure want to delete?"
            action={
                <Button variant="contained" color="error" onClick={onRemoveCard}>
                    Delete
                </Button>
            }
        />
    </Card>
};

export default GroupFlashCard;
