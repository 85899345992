import { Button, CircularProgress } from '@mui/material';
import { useEffect, useRef, useState } from 'react';

const AudioPlayer = ({ audioSrc }) => {
    const audioRef = useRef(null);  // Create a ref for the audio instance
    const [isPlaying, setIsPlaying] = useState(false);  // Playing state
    const [isLoading, setIsLoading] = useState(false);  // Loading state
    const [error, setError] = useState(false);          // Error state

    useEffect(() => {
        const audio = new Audio(audioSrc);  // Create a new Audio instance whenever audioSrc changes
        audioRef.current = audio;

        const handleCanPlay = () => {
            setIsLoading(false);  // Audio is ready to play, stop loading
        };

        const handleEnded = () => {
            setIsPlaying(false);  // Reset play state when audio ends
        };

        const handleError = () => {
            setError(true);        // Set error state when audio can't load
            setIsLoading(false);
        };

        audio.addEventListener('canplaythrough', handleCanPlay);
        audio.addEventListener('ended', handleEnded);
        audio.addEventListener('error', handleError);

        return () => {
            // Cleanup listeners and pause the audio when component unmounts
            audio.pause();
            audio.removeEventListener('canplaythrough', handleCanPlay);
            audio.removeEventListener('ended', handleEnded);
            audio.removeEventListener('error', handleError);
        };
    }, [audioSrc]);

    const handlePlayPause = () => {
        const audio = audioRef.current;

        if (isPlaying) {
            audio.pause();  // Pause the audio if it's playing
            setIsPlaying(false);
        } else {
            setIsLoading(true);  // Start loading when play is triggered
            audio.play()
                .then(() => {
                    setIsPlaying(true);   // Play the audio, then update playing state
                    setIsLoading(false);  // Stop loading after audio starts
                })
                .catch(() => {
                    setError(true);       // Handle play errors
                    setIsLoading(false);
                });
        }
    };

    return (
        <div style={{ textAlign: 'center', marginTop: '20px' }}>
            {isLoading ? (
                <CircularProgress size={24} />
            ) : (
                <Button variant="soft"
                    color={isPlaying ? "secondary" : "primary"}
                    onClick={handlePlayPause}
                // style={{ borderRadius: '20px', padding: '10px 20px' }} // Rounded, larger button
                >
                    {isPlaying ? 'Pause' : 'Play'}
                </Button>
            )}
            {error && <p style={{ color: 'red' }}>Error loading audio</p>}  {/* Show error message */}
        </div>
    );
};

export default AudioPlayer;
