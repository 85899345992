/* eslint-disable prettier/prettier */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import axiosInstance from 'src/utils/axios';
import { buildQueryString } from 'src/utils/function';

interface FlashCardState {
  flashCardData: {
    count: number;
    rows: any;
  };
  flashCardDataloading: boolean;
  createFlashDataSuccess: boolean;
  createFlashDataError: boolean;
  createFlashDataLoading: boolean;
  updateFlashDataSuccess: boolean;
  updateFlashDataError: boolean;
  updateFlashDataLoading: boolean;
  deleteFlashDataSuccess: boolean;
  deleteFlashDataError: boolean;
  deleteFlashDataLoading: boolean;
  getFlashCardLikeAndDislikeData: {
    totalLikes: any;
    totalDislikes: any;
  };
  bulkUploadSuccess: boolean;
  bulkUploadError: boolean;
  bulkUploadLoading: boolean;
}

const initialState: FlashCardState = {
  flashCardData: {
    count: 0,
    rows: [],
  },
  flashCardDataloading: false,
  createFlashDataError: false,
  createFlashDataSuccess: false,
  createFlashDataLoading: false,
  updateFlashDataError: false,
  updateFlashDataSuccess: false,
  updateFlashDataLoading: false,
  deleteFlashDataError: false,
  deleteFlashDataSuccess: false,
  deleteFlashDataLoading: false,
  getFlashCardLikeAndDislikeData: {
    totalDislikes: '',
    totalLikes: '',
  },
  bulkUploadSuccess: false,
  bulkUploadError: false,
  bulkUploadLoading: false,
};

const flashCardSlice = createSlice({
  name: 'flashCard',
  initialState,
  reducers: {
    startFlashCardDataloading(state) {
      state.flashCardDataloading = true;
    },
    flashCardData(state, action: PayloadAction<any>) {
      state.flashCardDataloading = false;
      state.flashCardData = action.payload;
    },
    stopFlashCardDataLoading(state) {
      state.flashCardDataloading = false;
    },
    createFlashDataLoading(state) {
      state.createFlashDataLoading = true;
    },
    createFlashCardDataSuccess(state) {
      state.createFlashDataSuccess = true;
      state.createFlashDataLoading = false;
    },
    createFlashCardDataError(state) {
      state.createFlashDataError = true;
      state.createFlashDataLoading = false;
    },
    clearCreateFlashCardData(state) {
      state.createFlashDataSuccess = false;
      state.createFlashDataLoading = false;
      state.createFlashDataError = false;
    },
    updateFlashDataLoading(state) {
      state.updateFlashDataLoading = true;
    },
    updateFlashCardDataSuccess(state) {
      state.updateFlashDataSuccess = true;
      state.updateFlashDataLoading = false;
    },
    updateFlashCardDataError(state) {
      state.updateFlashDataError = true;
      state.updateFlashDataLoading = false;
    },
    clearUpdateFlashCardData(state) {
      state.updateFlashDataSuccess = false;
      state.updateFlashDataLoading = false;
      state.updateFlashDataError = false;
    },
    deleteFlashDataLoading(state) {
      state.deleteFlashDataLoading = true;
    },
    deleteFlashCardDataSuccess(state) {
      state.deleteFlashDataSuccess = true;
      state.deleteFlashDataLoading = false;
    },
    deleteFlashCardDataError(state) {
      state.deleteFlashDataError = true;
      state.deleteFlashDataLoading = false;
    },
    clearDeleteFlashCardData(state) {
      state.deleteFlashDataSuccess = false;
      state.deleteFlashDataSuccess = false;
      state.deleteFlashDataLoading = false;
    },
    flashCardLikeAndDislikeData(state, action: PayloadAction<any>) {
      state.flashCardDataloading = true;
      state.getFlashCardLikeAndDislikeData = action.payload;
    },
    // Bulk upload reducers
    bulkUploadLoading(state) {
      state.bulkUploadLoading = true;
    },
    bulkUploadSuccess(state) {
      state.bulkUploadSuccess = true;
      state.bulkUploadLoading = false;
    },
    bulkUploadError(state) {
      state.bulkUploadError = true;
      state.bulkUploadLoading = false;
    },
    clearBulkUpload(state) {
      state.bulkUploadSuccess = false;
      state.bulkUploadError = false;
      state.bulkUploadLoading = false;
    },
  },
});

export const {
  startFlashCardDataloading,
  flashCardData,
  stopFlashCardDataLoading,
  createFlashCardDataError,
  createFlashCardDataSuccess,
  createFlashDataLoading,
  updateFlashCardDataError,
  updateFlashCardDataSuccess,
  updateFlashDataLoading,
  deleteFlashCardDataError,
  deleteFlashCardDataSuccess,
  deleteFlashDataLoading,
  flashCardLikeAndDislikeData,
  clearDeleteFlashCardData,
  clearUpdateFlashCardData,
  clearCreateFlashCardData,
  bulkUploadLoading,
  bulkUploadSuccess,
  bulkUploadError,
  clearBulkUpload,
} = flashCardSlice.actions;

export default flashCardSlice.reducer;

export const bulkUploadFlashCards =
  (data: any, enqueueSnackbar: any, onClose: any): any =>
  async (dispatch: any) => {
    try {
      dispatch(bulkUploadLoading());
      const response = await axiosInstance.post(`/flashcard/image/bulk-upload`, data); // Replace with your API endpoint
      await dispatch(bulkUploadSuccess());

      const { successCount, errorCount, errors } = response.data;

      // Display success message
      if (successCount > 0) {
        const successMessage =
          successCount === 1
            ? `1 card uploaded successfully.`
            : `${successCount} cards uploaded successfully.`;
        enqueueSnackbar(successMessage, { variant: 'success' });
      } else {
        enqueueSnackbar('No cards were uploaded successfully.', { variant: 'warning' });
      }

      // Display error message
      if (errorCount > 0) {
        const errorMessage =
          errorCount === 1 ? `1 card failed to upload.` : `${errorCount} cards failed to upload.`;
        enqueueSnackbar(errorMessage, { variant: 'error', autoHideDuration: null });

        // Display specific errors if available
        errors.forEach((error) => {
          enqueueSnackbar(`${error.filename}: ${error.message}`, {
            variant: 'error',
            autoHideDuration: null,
          });
        });
      }

      // Handle case when no cards are uploaded
      if (successCount === 0 && errorCount === 0) {
        enqueueSnackbar('No cards were uploaded.', { variant: 'info' });
      }

      dispatch(getAllFlashCardForAdmin({ page: 1, pageSize: 10 }));
      onClose();
    } catch (error) {
      dispatch(bulkUploadError());
      enqueueSnackbar(error.message, { variant: 'error' });
    }
  };

export const getAllFlashCardForAdmin =
  (query: Params): any =>
  async (dispatch: any) => {
    try {
      dispatch(startFlashCardDataloading());
      const response = await axiosInstance.get(
        `/flashcard/getallflashcardsforadmin${buildQueryString(query)}`
      ); // Replace with your API endpoint
      // const response = await axiosInstance.get(
      //   `/proposedcard/admin?page=${page ?? 1}&pageSize=${pageSize ?? 10}`
      // ); // Replace with your API endpoint
      dispatch(flashCardData(response.data.data));
    } catch (error) {
      dispatch(stopFlashCardDataLoading(error.message));
      // enqueueSnackbar(error.message, { variant: 'error' });
    }
  };

export const createFlashCardForAdmin =
  (data: any, enqueueSnackbar: any): any =>
  async (dispatch: any) => {
    try {
      dispatch(createFlashDataLoading());
      await axiosInstance.post(`/flashcard`, data); // Replace with your API endpoint
      dispatch(createFlashCardDataSuccess());
      dispatch(getAllFlashCardForAdmin({ page: 1, pageSize: 10 }));
    } catch (error) {
      dispatch(createFlashCardDataError());
      enqueueSnackbar(error.message, { variant: 'error' });
    }
  };

export const updateFlashCardForAdmin =
  (data: any, id: number, enqueueSnackbar: any): any =>
  async (dispatch: any) => {
    try {
      dispatch(updateFlashDataLoading());
      await axiosInstance.patch(`/flashcard/${id}`, data); // Replace with your API endpoint
      dispatch(updateFlashCardDataSuccess());
      dispatch(getAllFlashCardForAdmin({ page: 1, pageSize: 10 }));
    } catch (error) {
      dispatch(updateFlashCardDataError());
      enqueueSnackbar(error.message, { variant: 'error' });
    }
  };

export const deleteFlashCardForAdmin =
  (id: number): any =>
  async (dispatch: any) => {
    try {
      dispatch(deleteFlashDataLoading());
      await axiosInstance.patch(`/flashcard/delete/${id}`); // Replace with your API endpoint
      dispatch(deleteFlashCardDataSuccess());
      dispatch(getAllFlashCardForAdmin({ page: 1, pageSize: 10 }));
    } catch (error) {
      dispatch(deleteFlashCardDataError());
    }
  };

export const fetchFlashCardLikeAndDislikeData =
  (id: number): any =>
  async (dispatch: any) => {
    try {
      const response = await axiosInstance.get(`flashcard/getflashcardstats/${id}`); // Replace with your API endpoint
      dispatch(flashCardLikeAndDislikeData(response.data));
    } catch (error) {
      console.log('error', error);
    }
  };
