/* eslint-disable prettier/prettier */
import { Autocomplete, Chip, InputAdornment, TextField } from '@mui/material';
import { debounce } from 'lodash';
import { useCallback } from 'react';
import { sortByProperty } from 'src/utils/function';
import Iconify from '../iconify/Iconify';

const CustomSearchBar = ({ setSearchTerm, placeholder = "Search", autocomplete = false, options = [], loadMoreOptions, selectedOptions, setSelectedOptions, chipsoff }) => {
    // const [selectedOptions, setSelectedOptions] = useState([]);

    const handleSearchChange = useCallback(
        debounce((event) => {
            setSearchTerm(event.target.value);
            if (autocomplete) {
                loadMoreOptions();
            }
        }, 500),
        [setSearchTerm, loadMoreOptions, autocomplete]
    );

    const handleOptionChange = (event, value) => {
        if (value && !selectedOptions.includes(value)) {
            setSelectedOptions([...selectedOptions, value]);
        }
    };

    const handleOptionDelete = (optionToDelete) => {
        setSelectedOptions(selectedOptions.filter(option => option !== optionToDelete));
    };
    console.log(selectedOptions)
    return (
        <div>
            {autocomplete ? (
                <Autocomplete
                    freeSolo
                    options={sortByProperty(options, 'term')}
                    getOptionLabel={(option) => `${option?.term} (${option?.definition})`}
                    onChange={handleOptionChange}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            placeholder={placeholder}
                            onChange={handleSearchChange}
                            InputProps={{
                                ...params.InputProps,
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <Iconify
                                            icon="eva:search-fill"
                                            sx={{ ml: 1, width: 20, height: 20, color: 'text.disabled' }}
                                        />
                                    </InputAdornment>
                                ),
                            }}
                        />
                    )}
                />
            ) : (
                <TextField
                    onChange={handleSearchChange}
                    placeholder={placeholder}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <Iconify
                                    icon="eva:search-fill"
                                    sx={{ ml: 1, width: 20, height: 20, color: 'text.disabled' }}
                                />
                            </InputAdornment>
                        ),
                    }}
                />
            )}
            {autocomplete && !chipsoff && (
                <div style={{ marginTop: 16 }}>
                    {selectedOptions.map((option, index) => (
                        <Chip
                            key={index}
                            label={option?.term}
                            onDelete={() => handleOptionDelete(option)}
                            style={{ margin: 4 }}
                        />
                    ))}
                </div>
            )}
        </div>
    );
};

export default CustomSearchBar;

// /* eslint-disable prettier/prettier */
// import { InputAdornment, TextField, Autocomplete } from '@mui/material';
// import React, { useCallback, useState } from 'react';
// import Iconify from '../iconify/Iconify';
// import { debounce } from 'lodash';

// const CustomSearchBar = ({
//     setSearchTerm,
//     search,
//     placeholder = 'Search',
//     autocomplete = false,
//     options = [],
// }) => {
//     const handleSearchChange = useCallback(
//         debounce((event) => {
//             setSearchTerm(event.target.value);
//         }, 500),
//         [setSearchTerm]
//     );

//     const handleOptionChange = (event, value) => {
//         if (value?.term) {
//             console.log(value?.term)
//             setSearchTerm(value?.term);
//         }
//         else if (value) {
//             setSearchTerm(value)
//         }
//     };
//     // console.log(search)
//     return (
//         <>
//             {autocomplete ? (
//                 <Autocomplete
//                     freeSolo
//                     options={options}
//                     // getOptionLabel={(option) => (
//                     //     <div key={option?.id}>
//                     //         <p style={{ margin: 0 }}>{option?.term}</p>
//                     //         <p style={{ margin: 0 }}>{option?.definition}</p>
//                     //         <p style={{ margin: 0 }}>{option?.SimilarWords?.map((simWord) => simWord?.word)?.join(', ')}</p>
//                     //     </div>
//                     // )}
//                     getOptionLabel={(options) => options?.term}
//                     value={search}
//                     onChange={handleOptionChange}
//                     renderInput={(params) => (
//                         <TextField
//                             {...params}
//                             key={params?.id}
//                             placeholder={placeholder}
//                             InputProps={{
//                                 ...params.InputProps,
//                                 startAdornment: (
//                                     <InputAdornment position="start">
//                                         <Iconify
//                                             icon="eva:search-fill"
//                                             sx={{ ml: 1, width: 20, height: 20, color: 'text.disabled' }}
//                                         />
//                                     </InputAdornment>
//                                 ),
//                             }}
//                         />
//                     )}
//                 />
//             ) : (
//                 <TextField
//                     onChange={handleSearchChange}
//                     placeholder={placeholder}
//                     InputProps={{
//                         startAdornment: (
//                             <InputAdornment position="start">
//                                 <Iconify
//                                     icon="eva:search-fill"
//                                     sx={{ ml: 1, width: 20, height: 20, color: 'text.disabled' }}
//                                 />
//                             </InputAdornment>
//                         ),
//                     }}
//                 />
//             )}
//         </>
//     );
// };

// export default CustomSearchBar;

// // /* eslint-disable prettier/prettier */
// // import { InputAdornment, TextField } from '@mui/material'
// // import React, { useCallback } from 'react'
// // import Iconify from '../iconify/Iconify'
// // import { debounce } from 'lodash';

// // const CustomSearchBar = ({ setSearchTerm, placeholder = "Search" }) => {
// //     const handleSearchChange = useCallback(
// //         debounce((event) => {
// //             setSearchTerm(event.target.value);
// //         }, 500),
// //         []
// //     );
// //     return (
// //         <TextField

// //             onChange={handleSearchChange}
// //             placeholder={placeholder}
// //             InputProps={{
// //                 // ...params.InputProps,
// //                 startAdornment: (
// //                     <InputAdornment position="start">
// //                         <Iconify
// //                             icon="eva:search-fill"
// //                             sx={{ ml: 1, width: 20, height: 20, color: 'text.disabled' }}
// //                         />
// //                     </InputAdornment>
// //                 ),
// //             }}
// //         />
// //     )
// // }

// // export default CustomSearchBar
