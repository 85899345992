/* eslint-disable prettier/prettier */
import { Autocomplete, InputAdornment, TextField } from '@mui/material';
import { debounce } from 'lodash';
import { useCallback, useState } from 'react';
import { sortByProperty } from 'src/utils/function';
import Iconify from '../iconify/Iconify';

const FlashcardGroupAdd = ({ setSearch, placeholder = "Search", autocomplete = false, options = [], handleOptionChange, loadMoreOptions }) => {
    // const [selectedOptions, setSelectedOptions] = useState([]);
    const [loading, setloading] = useState(false)
    const handleSearchChange = useCallback(
        debounce(async (event) => {
            setSearch(event.target.value);
            if (autocomplete) {
                setloading(true)
                await loadMoreOptions();
                setloading(false)
            }
        }, 500),
        [setSearch, loadMoreOptions, autocomplete]
    );


    return (
        <div style={{ marginBottom: "15px" }}>

            <Autocomplete
                freeSolo
                options={sortByProperty(options, 'term')}
                getOptionLabel={(option) => `${option?.term} (${option?.definition})`}
                onChange={(ev, value) => { handleOptionChange(value) }}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        placeholder={placeholder}
                        onChange={handleSearchChange}
                        InputProps={{
                            ...params.InputProps,
                            startAdornment: (
                                <InputAdornment position="start">
                                    <Iconify
                                        icon="eva:search-fill"
                                        sx={{ ml: 1, width: 20, height: 20, color: 'text.disabled' }}
                                    />
                                </InputAdornment>
                            ),
                        }}
                    />
                )}
            />
            {options?.length < 1 && !loading && (
                <div style={{ color: '#888', fontStyle: 'italic', padding: '8px 0', textAlign: "center" }}>
                    No flashcards found to add. Please try searching with a different keyword.
                </div>
            )}
            {loading && <div style={{ color: '#888', fontStyle: 'italic', padding: '8px 0', textAlign: "center" }}>
                Loading...</div>}

        </div>
    );
};

export default FlashcardGroupAdd;