export const buildQueryString = (params: Params): string => {
  const queryParts: string[] = [];

  for (const key in params) {
    if (params.hasOwnProperty(key)) {
      const value = params[key];

      if (key.startsWith('autogenerate-mul-array-') && Array.isArray(value)) {
        const arrayKey = key.slice('autogenerate-mul-array-'.length);
        value.forEach((item) => {
          queryParts.push(
            `${arrayKey}=${item}`
            // `${encodeURIComponent(arrayKey)}=${encodeURIComponent(item)}`
          );
        });
      } else {
        // Handle other cases
        if (value && value !== null && value !== undefined && value !== '' && !!value)
          queryParts.push(
            `${key}=${value}`
            // `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
          );
      }
    }
  }

  return queryParts.length > 0 ? `?${queryParts.join('&')}` : '';
};

// export function sortByProperty(array = [], property = '') {
//   if (!Array.isArray(array) || typeof property !== 'string') {
//     throw new Error('Invalid arguments');
//   }

//   return array?.slice()?.sort((a, b) => {
//     const nameA = a?.[property]?.toLowerCase() || '';
//     const nameB = b?.[property]?.toLowerCase() || '';

//     if (nameA < nameB) return -1;
//     if (nameA > nameB) return 1;
//     return 0;
//   });
// }
export function sortByProperty<T>(array: T[] = [], property: keyof T | '' = ''): T[] {
  if (!Array.isArray(array) || (property && typeof property !== 'string')) {
    throw new Error('Invalid arguments');
  }

  return array.slice().sort((a, b) => {
    // Only proceed if property is defined and non-empty.
    if (property) {
      const valueA = (a[property] as unknown as string)?.toLowerCase() || '';
      const valueB = (b[property] as unknown as string)?.toLowerCase() || '';

      if (valueA < valueB) return -1;
      if (valueA > valueB) return 1;
    }
    return 0;
  });
}

export const phoneRegExp = /^(\+\d{1,3}[- ]?)?\d{10}$/;
